<template>

    <div class="card bg-color">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <!-- <h3><span STYLE="font-weight:bold">Status: </span>{{ leadsInfo['data']['data']['status'] }}</h3> -->

            <table>
              <tbody>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Name: </span>
                </td>
                <td>
                  {{ leadsInfo['data']['data']['name'] }}<br>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Address: </span>
                </td>
                <td>
                  {{ leadsInfo['data']['data']['address'] }}<br>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Website: </span>
                </td>
                <td>
                  <span v-if="leadsInfo['data']['data']['website']   != null"><a :href="leadsInfo['data']['data']['website']" target="_blank">{{ leadsInfo['data']['data']['website'] }}</a></span>
                  <span v-else>{{ 'Not Available' }}</span>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Phone: </span>
                </td>
                <td>
                  <a :href=" `tel:${leadsInfo['data']['data']['phone']}` ">{{ leadsInfo['data']['data']['phone'] }}</a>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Rating: </span>
                </td>
                <td>
                  {{ leadsInfo['data']['data']['rating'] }}<br>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Location: </span>
                </td>
                <td>
                  <a :href="leadDetails.url" target="_blank">{{ leadDetails.url }}</a><br>
                </td>
              </tr>

              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">UTC Offset: </span>
                </td>
                <td>
                  {{ leadDetails.utc_offset }}<br>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Business Status: </span>
                </td>
                <td>
                  {{ leadDetails.business_status }}<br>
                </td>
              </tr>
              <tr>
                <td class="col-md-3">
                  <span STYLE="font-weight:bold">No. of user Rated: </span>
                </td>
                <td>
                  {{ leadDetails.user_ratings_total }}<br>
                </td>
              </tr>
              <tr class="text-align-center">

                <td class="col-md-3">
                  <span STYLE="font-weight:bold">Status: </span>
                </td>
                <td class="col-md-7">
                  <form v-on:click="updateStatus($event)">
                    <select name="status"  class="form-control bg-secondary text-white" v-if="leadsInfo['data']['data']['status'] == 0">
                      <option value="0" selected>Not Contacted</option>
                      <option value="1" >Contacted</option>
                      <option value="2" >On Process</option>
                      <option value="3">Converstaion Done</option>
                    </select>

                    <select name="status" class="form-control bg-secondary text-white" v-if="leadsInfo['data']['data']['status'] == 1">
                      <option value="0" >Not Contacted</option>
                      <option  value="1" selected >Contacted</option>
                      <option value="2" >On Process</option>
                      <option value="3">Converstaion Done</option>
                    </select>

                    <select  name="status" class="form-control  bg-secondary text-white" v-if="leadsInfo['data']['data']['status'] == 2">
                      <option value="0" >Not Contacted</option>
                      <option value="1" >Contacted</option>
                      <option value="2" selected >On Process</option>
                      <option value="3">Converstaion Done</option>
                    </select>

                    <select  name="status" class="form-select form-control bg-secondary text-white margin-left" v-if="leadsInfo['data']['data']['status'] == 3">
                      <option value="0" >Not Contacted</option>
                      <option value="1" >Contacted</option>
                      <option value="2"  >On Process</option>
                      <option value="3" selected>Converstaion Done</option>
                    </select>
                  </form>
                </td>
                <td class="col-md-2">
                  <button type="submit" v-on:click="onClick($event)" class="btn btn-primary float-right">Change</button>
                </td>

              </tr>



            </tbody>
            </table>

            <!-- @php
              $details = leadsInfo['data']['data']['details']['url']
            @endphp
            <h3><span STYLE="font-weight:bold">Rating: </span> {{  $details['url'] }}</h3> -->
            <!-- <h3>
              <ul v-for="item in leadsInfo['data']['data']['details']">
                <li v-for="data in item['reviews']">
                  <li>{{ data }}</li>
                </li>
              </ul>
            </h3> -->

          </div>

          <!-- <div class="col-md-2">
            <form v-on:click="updateStatus($event)">

              <select name="status"  class="form-control" v-if="leadsInfo['data']['data']['status'] == 0">
                <option value="0" selected>Not Contacted</option>
                <option value="1" >Contacted</option>
                <option value="2" >On Process</option>
                <option value="3">Converstaion Done</option>
              </select>

              <select  name="status" class="form-control" v-if="leadsInfo['data']['data']['status'] == 1">
                <option value="0" >Not Contacted</option>
                <option value="1" selected >Contacted</option>
                <option value="2" >On Process</option>
                <option value="3">Converstaion Done</option>
              </select>

              <select  name="status" class="form-control" v-if="leadsInfo['data']['data']['status'] == 2">
                <option value="0" >Not Contacted</option>
                <option value="1" >Contacted</option>
                <option value="2" selected >On Process</option>
                <option value="3">Converstaion Done</option>
              </select>

              <select  name="status" class="form-control" v-if="leadsInfo['data']['data']['status'] == 3">
                <option value="0" >Not Contacted</option>
                <option value="1" >Contacted</option>
                <option value="2"  >On Process</option>
                <option value="3" selected>Converstaion Done</option>
              </select>
            <button type="submit"  v-on:click="onClick($event)" class="btn btn-primary mt-1 float-right">Change</button>
          </form>
          </div> -->

        </div>



      </div>
    </div>
  </template>

      <script>
      import {
        BCard,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BForm,
        BModal,
        BRow,
        BCol,
        BInputGroupAppend,
        BInputGroup,
        BSpinner,
      } from "bootstrap-vue";
      import { VueGoodTable } from "vue-good-table";
      import Ripple from "vue-ripple-directive";
      import { ValidationProvider, ValidationObserver } from "vee-validate";
      import { required, email, max, mimes, size, confirmed } from "@validations";

      import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
      import { mapGetters } from "vuex";
      import { togglePasswordVisibility } from "@core/mixins/ui/forms";
      import { permissionsConstant } from "@/helpers/permissionsConstant";

      export default {
        name: "LeadDetails",

        data() {
      return {
        stat: '',
        leadsInfo: '',
        leadDetails: '',
        leadId: '',
        leadStatus: [
          { label: "Male", value: "Male" },
          { label: "Female", value: "Female" },
        ],
      };
    },
    methods: {
      async showDetails(id){

        const result = this.$router.push({
          name: "lead-details",
          params: { id },
          updateId: null,
        });

        },

      async showAllData(id){
         const response = await this.$api.post(
                  "/api/lead-details/"+this.$route.params.id
                );

          // this.leadsInfo = response['data']['data'];
          this.leadsInfo = response;
          this.leadDetails = JSON.parse(response['data']['data']['details']);

        },



        updateStatus(e){
          // console.log(e.target.value);
          this.updateId = e.target.value;


              //   const res = this.$api.post(`/api/lead-update/${this}`, {

              //   });

              //   if(res){
              //   // this.$toast({
              //   //   component: ToastificationContent,
              //   //   props: {
              //   //     title: "Success",
              //   //     icon: "BellIcon",
              //   //     variant: "success",
              //   //     text: "Campaign successfully Updated",
              //   //   },
              //   // });
              // }


        },
        onClick(e){
          e.preventDefault();
          this.leadId = this.$route.params.id;
                const res = this.$api.post(`/api/lead-update/${this.updateId}`, {
                  lead_id: this.leadId,
                  update_id: this.updateId,
                });
                showAllData();

                  if(res){
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Status successfully Updated",
                  },
                });
              }
        }
    },
        async created() {
          showAllData();
    },

      };
      </script>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-good-table.scss";

      .bg-color {
        background-color: #e4f1f7;
      }

      .margin-left{
        margin-left: 0 !important;
      }
      </style>
